/*eslint comma-dangle: [2, "always"]*/
export default {
  // General
  'Next': 'Næste',
  'NEXT': 'NÆSTE',
  'SEND': 'SENDE',
  'Back to the homepage': 'Tilbage til hjemmesiden',
  'Back to the Braun website': 'Tilbage til Braun-siden',
  'Back': 'Tilbage',
  'LOG IN': 'LOG IND',
  'SIGN UP': 'TILMELD DIG', // Missing
  '(optional)': '(valgfrit)', // Missing
  'Cookie Text': 'Tilladelse af cookies',
  'or': 'eller',

  // Header
  'Skip to Main Content': 'Spring Til Hovedindhold',
  'Terms and Conditions': 'Betingelser og vilkår',
  'FAQ': 'Ofte Stillede Spørgsmål', // Missing

  // Footer
  'Footer Support Text': 'Vores supportafdeling er tilgængelig indtil den 15.12.2025: <a href="mailto:contact@braun-moneyback.com" ><span><b>contact@braun-moneyback.com</b></span></a>',
  'terms': 'Betingelser og vilkår',
  'Privacy': 'Privatlivsbemærkning',
  'Preference': 'Mine Data',
  'Impressum': 'Aftryk',
  'Sitemap': 'Siden oversigt',

  // Home Screen
  'START REFUND': 'Start Refusion',
  'Step 1': 'Trin 1',
  'Step 2': 'Trin 2',
  'Step 3': 'Trin 3',
  'Step 4': 'Trin 4',
  'Select your product': 'Vælg dit produkt',
  'Upload a receipt': 'Upload din kvittering',
  'Register': 'Tilmeld',
  'Get your money back': 'Få dine penge tilbage',
  'bannerAltText': 'Fuld tilfredshed eller pengene tilbage!* Betingeler og vilkår gælder',

  // Progress Bar

  'Select<br>product': 'Vælg<br>produkt',
  'Upload<br>a receipt': 'Upload<br>din kvittering',
  'Get your<br>money back': 'Få dine<br>penge tilbage',

  // Product Select Page
  'Scan the product': 'Scan produktet',
  'Type in or scan the barcode and serial number': 'Indtast eller scan stregkoden',
  'Please enter the serial number and barcode number of your Braun products, take a photo of your promotional product or upload it using <b>Drag & Drop</b>. Please make sure that the barcode information of your promotional product is clearly visible.': 'Indtast stregkodenummeret på dit Braun tandpasta-produkt, tag et foto af dit reklameartikler eller upload det ved hjælp af <b>træk og slip</b>. Sørg for, at stregkodeoplysningerne på dit salgsfremmende produkt er tydeligt synlige.',
  'Upload': 'Upload',
  'Upload<br>barcode image': 'Upload<br>stregkodebillede',
  'Drag & Drop': 'Træk og Slip',
  'Scan': 'Scan',
  'Stop': 'Hold op',
  'Scan<br>barcode image': 'Scan<br>stregkodebillede',
  '(the easiest)': '(det nemmeste)',
  'Add': 'Tilføj til',
  'Add<br>barcode number': 'Tilføj stregkode',
  'Enter Barcode number': 'Indtast Stregkodenummer',
  'Serial number': 'Serienummer',
  'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.': 'Dette filformat understøttes ikke. Kun .jpeg-, .png-, .pdf- og .tiff-filer accepteres.',
  'The file is too large. Only files up to 16 MB are supported.': 'Filen er for stor. Der understøttes kun filer på op til 16 MB.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Desværre kan der ikke genkendes nogen gyldig stregkode. Prøv venligst at indtaste stregkoden i feltet ovenfor.',
  'Please turn on your camera': 'Tænd venligst for dit kamera',
  'Correct Barcode Number': 'Stregkodenummeret er gyldigt',
  'Wrong Barcode Number': 'Stregkodenummeret er ugyldigt',
  'You cannot enter more than one product.': 'Du kan ikke indtaste mere end ét produkt.',

  // Receipt Upload Page

  'Upload receipt': 'Upload kvittering',
  'Upload a picture of your proof of purchase in this step.': 'Upload et billede af dit købsbevis i dette trin.',
  'Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Upload dit kvitteringsbillede ved hjælp af træk og slip. Kun JPEG, PDF og PNG understøttes. Maksimal filstørrelse: 16 MB.',
  'Please photograph your proof of purchase!': 'Tag venligst et billede af dit købsbevis!',
  'Make sure that the following information is clearly visible:': 'Sørg for, at følgende oplysninger er tydeligt synlige:',
  'Dealer / Market name': 'Forhandler / Marked',
  'Promotional product and purchase price': 'Salgsfremmende produkt og købspris',
  'Total of the proof of purchase and VAT': 'Samlet beløb for købsbeviset og moms',
  'Date and time of purchase': 'Dato og tidspunkt for købet',
  'Proof of purchase ID': 'Købsbevisets ID',
  'Upload your receipt photo': 'Upload dit kvitteringsbillede',
  'Drag & Drop or click here': 'Træk og Slip eller klik her',
  'Choose a File': 'Vælg en fil',
  'Choose Another': 'Vælg en anden', // Missing
  'Crop': 'Beskær',
  'Erase': 'Gnid ud',
  'Move': 'Flyt', // Missing
  'Zoom In': 'Zoom ind',
  'Zoom Out': 'Zoom ud',
  'You can erase private information with the razor tool.': 'Du kan slette private oplysninger med razor-værktøjet.',
  'If you get an error message, please take a better version of the receipt and upload it again.': 'Hvis du får en fejlmeddelelse, skal du tage en bedre version af kvitteringen og uploade den igen.',
  'The receipt is processed automatically.This can take a minute.Please be patient.': 'Kvitteringen behandles automatisk.Dette kan tage et minut.Vær venligst tålmodig.',
  'Your receipt will now be uploaded and processed.': 'Din kvittering vil nu blive uploadet og behandlet.',
  'Please drag your receipt into the gray rectangle.': 'Venligst træk din kvittering ind i det grå rektangel.',

  // Product upload page

  'Upload product photo': 'Upload produktfoto',
  'Please upload a photo of the product side on which the EAN barcode is clearly visible.': 'Venligst upload venligst et billede af produktsiden, hvor EAN-stregkoden er tydeligt synlig.',
  'Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Upload dit produktfotoet ved hjælp af træk og slip. Kun JPEG, PDF og PNG understøttes. Maksimal filstørrelse: 16 MB.',
  'Upload your product photo': 'Upload dit produktfotoet',

  // Banking page
  'What is IBAN and where do I find it?': 'Hvad er IBAN, og hvor kan jeg finde det?',
  "IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.": `IBAN står for Internationale Bankkontonummer.Det er en internationalt godkendt kode bestående af op til 34 bogstaver og tal, som hjælper bankerne med at behandle overførsler i hele verden.
  <br>
  <br>
  Du kan normalt finde dit IBAN- nummer øverst til højre på dit kontoudtog eller i din netbank.Hvis du ikke kan finde dit IBAN - nummer, bør du kunne generere det online via din banks netbank eller ved at bruge et IBAN - beregnerværktøj.`,
  'What is BIC and where do I find it?': 'Hvad er BIC, og hvor finder jeg den ?',
  "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC.": `BIC står for Bankidentifikationskode.For at sende eller modtage penge skal enhver finansiel institution vide, hvor pengene skal sendes hen, og BIC er præcis det, der viser det.
  <br>
  <br>
  Du kan normalt finde din banks BIC på dine kontoudtog.Hvis du bruger netbank, kan du logge ind på din digitale bankkonto for nemt at se dit kontoudtog eller kontakte din bank, så de kan give dig din BIC.`,
  'Enter bank details': 'Indtast bankoplysninger',
  'Please enter the first and last name of the account holder exactly as they were given at the bank.': '"Venligst indtast for- og efternavn på kontohaveren nøjagtigt som de blev oplyst i banken.',
  'Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.': 'Undgå at ændre umlautterne: <b>Müller eller Mueller</b>; Undgå at tilføje eller udelade bindestreger: <b>Marie Luise eller Marie- Luise</b>; Angiv kun mellemnavnet, hvis kontoen også forvaltes med det.',
  'Enter your IBAN': 'Indtast dit IBAN',
  'Enter your BIC': 'Indtast dit BIC',
  'Enter your first name': 'Indtast dit fornavn',
  'Enter your last name': 'Indtast dit efternavn',
  'Account holder first name': 'Kontoindehaverens fornavn',
  'Account holder last name': 'Kontoindehaver efternavn',
  'If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.': 'Hvis oplysningerne ikke stemmer nøjagtigt overens med kontooplysningerne, vil banken i de fleste tilfælde opkræve tilbageførsel og gøre processen med en ukompliceret og hurtig tilbagebetaling vanskeligere.',

  // Address page

  'Your address': 'Din adresse',
  'Streetname': 'Vejnavn',
  'House number': 'Nummer',
  'Postal code': 'Postnummer',
  'City': 'By',
  'Country': 'Land',
  'Enter your streetname': 'Indtast dit streetname',
  'Enter the number of your house': 'Indtast nummeret på dit hus',
  'Enter your postal code': 'Indtast dit postnummer',
  'Enter a city you live': 'Indtast en by, du bor i', // Missing
  'Choose a country': 'Vælg et land', // Missing

  // Login page

  'Log in with an Braun account': 'Log ind med en Braun-konto',
  'Log in with<br>Braun account': 'Log ind med<br>Braun-konto',
  'Email address': 'E-mailadresse',
  'Enter your email address': 'Indtast dit e-mailadresse',
  'Password': 'Adgangskode',
  'Enter your password': 'Indtast dit adgangskode',
  'Forgot password?': 'Glemt adgangskode?',
  "Don't have<br>an account yet?": "Har du ikke<br>en Braun-konto endnu?",
  'Continue<br>as a guest': 'Fortsæt<br>som gæst',

  // Registration page

  'Registration form': 'Tilmeldingsformular',
  "Register now and don't miss a thing.": "Registrer dig nu, og gå ikke glip af noget som helst.",
  'Braun informs you regularly about further "free test" campaigns and also offers exclusive free product tests every week.': 'Vi har brug for følgende oplysninger fra dig for at kunne behandle betalingen. Disse oplysninger bruges kun til at behandle Braun pengene-tilbage kampagnen.',
  'Personal information': 'Personlige oplysninger', // Missing
  'Title': 'Titel',
  'Mrs.': 'Fru',
  'Mr.': 'Hr.',
  'First name': 'Fornavn',
  'Last name': 'Efternavn*',
  'Date of birth': 'Fødselsdato',
  'dd/mm/yyyy': 'dd/mm/åååå', // missing
  'Account information': 'Kontooplysninger', // Missing
  'E-mail': 'E-mail',
  'Enter your email': 'Indtast dit e-mail',
  'Create a strong password': 'Opret en stærk adgangskode', // Missing
  'I confirm that I am over 18 years old. I confirm the terms and conditions, privacy policy & Justsnap privacy policy.': 'Jeg bekræfter, at jeg er over 18 år gammel. Jeg bekræfter <a href="https://termsandconditions.pg.com/da-dk/" target="_blank"><u>vilkår og betingelser</u></a>, <a href="https://privacypolicy.pg.com/da-DK" target="_blank"><u>privatlivspolitik</u></a> og <a href="https://justsnap.de/Datenschutz/Data_Policy_DE.pdf" target="_blank"><u>Justsnap-politik om beskyttelse af personlige oplysninger</u></a>.',
  'I have read the Conditions of participation for this promotion and accept them.': 'Jeg har læst <a href="https://braun-moneyback.com/dk/terms" target="_blank"><u>betingelserne</u></a> for deltagelse i denne kampagne og accepterer dem.',
  'Always up-to-date with our newsletters. I want to receive emails, offers and coupons from Oral-B - the community for power women and super moms - and other trusted P&G brands.': `<strong>Hold kontakten med os ved lige!</strong> Jeg accepterer at modtage personlig kommunikation om tilbud, nyheder og andre salgsfremmende initiativer fra Braun og andre <a href='https://us.pg.com/brands/' target='_blank'><strong><u>P&G-brands</u></strong></a> via e-mail og onlinekanaler. Jeg kan til enhver tid <a href='https://preferencecenter.pg.com/da-dk/datarequests/' target='blank'><strong><u>trække</u></strong></a> samtykket tilbage.`,
  'Registration Notice': `<div style="font-size: 16px; font-family: 'Helvetica'; padding-left: 25px;">Procter & Gamble, den dataansvarlige, behandler dine oplysninger for at give dig mulighed for at registrere dig på dette websted, interagere med dets tjenester og, afhængigt af dit samtykke, sende dig relevante kommercielle meddelelser, herunder personligt tilpassede annoncer i onlinemedier.
    Få mere at <p class='modalOpener' data-text="<div>*Få mere at vide
    <br>Relevante annonceringsoplysninger og personligt tilpassede onlineannoncer
    <br>Vores mål er at gøre din rejse med os og med P&G-familiens mærker interessant, behagelig og personlig. Det vil vi gøre ved at forsøge at designe vores kommercielle meddelelser omkring det, du bedst kan lide, undgå at sende dig forslag, der ikke falder i din smag, eller vise dig annoncer, som du allerede har set mange gange. Hvordan vil vi gøre det?
    <ul>
      <br><li>Kombination af data for bedre at tilpasse vores meddelelser til dine interesser. Vi kan, baseret på vores legitime interesse, berige dine registreringsoplysninger samt oplysninger, som du videregiver til os, når du interagerer med vores tjenester (mærkepræferencer, anvender kuponer, osv.), med attributter, interesser, demografiske oplysninger indsamlet fra kommercielt tilgængelige kilder eller andre tredjeparter. Tilsvarende, hvis du har accepteret vores målrettede annonceringscookies eller lignende teknologier på P&G eller, i nogle tilfælde, tredjeparters websteder eller apps, kan vi supplere de oplysninger, der indsamles gennem vores målrettede annonceringscookies, med andre oplysninger, som du tilvejebringer for os, når du opretter en konto eller interagerer med vores tjenester.</li>
    </ul>
    <br>Vi behandler disse oplysninger, herunder på automatiserede måder, for at tilpasse vores indhold og tilbud via e-mail, online (for eksempel på tredjepartsplatforme, herunder sociale medieplatforme) og offline (for eksempel med post), hvis du har givet samtykke til at modtage kommercielle meddelelser fra os.
    <ul>
      <br><li>Annoncering til personer med en lignende profil. Baseret på vores legitime interesse analyserer vi også dine oplysninger for at identificere nye kontakter, der med en profil, som ligner din, kan være interesseret i at høre fra os. For eksempel kan vi og vores tilknyttede P&G-selskaber uploade din hashkrypterede e-mailadresse til Facebook og bede Facebook om at vise vores annoncer til personer, der har lignende interesser som dig, herunder i andre lande, baseret på data, som Facebook har om dig og om andre mennesker.</li>
      <br><li>Brug af dine personoplysninger til at kontakte dig på onlinemedier. Hvis du har givet samtykke til at modtage kommercielle meddelelser fra os, bruger vi dine hashkrypterede personoplysninger (dvs. din e-mailadresse, dit telefonnummer eller dit mobilannoncerings-id) til at sende dig annoncer gennem onlinekanaler (f.eks. på din konto på sociale medier). Vi gør dette gennem en proces, der respekterer dit privatliv ved at bruge krypterede oplysninger og nøje udvalgte partnere. Konkret deler vi dine personoplysninger med tredjeparter, der leverer skræddersyede annonceringstjenester til os, herunder sociale medieplatforme. Vi bruger sådanne tjenester til at matche de hashkrypterede oplysninger, som vi har, med personoplysninger i deres database for at skabe tilpassede målgrupper og skræddersy annoncering til dine interesser på internettet, herunder sociale medier, som tilladt ved gældende lovgivning.</li>
    </ul>
    <br>Dette vil gøre det muligt at sende dig onlineannoncer (tilbud, oplysninger om produkter og innovationer), der er mere relevante, samt vise dig færre annoncer, da vi ved, hvornår vi allerede har vist dig en bestemt annonce og kan udelukke dig fra at modtage yderligere meddelelser om det samme emne. De fleste af vores forbrugere sætter pris på, at vi ikke viser dem annoncer for produkter, som de ikke er interesseret i, eller som vi har vist dem mange gange før, men hvis du ikke gør det, er det ikke noget problem: Du kan altid ændre din præferencer.
    <br>Du kan altid ændre mening og trække dit samtykke tilbage/fravælge brugen af dine data til personlig kommunikation og annoncering, herunder oprettelse af brugerdefinerede målgrupper og målgrupper, der minder om dig, <a href='https://preferencecenter.pg.com/da-dk/datarequests/' target='_blank'><strong><u>her</u></strong></a>. Læs vores privatlivspolitik</div>"
    >vide*</p>.
    Du bedes læse <p class='modalOpener' data-text="**Vi værdsætter dit privatliv
    <div>
      <ul>
        <br><li>Vores privatlivspolitik regulerer vores indsamling og brug af dine oplysninger</li>
        <br><li>Det er årsagen <a href='https://privacypolicy.pg.com/da-DK/' target='_blank'><strong><u>til</u></strong></a> at vi indsamler dine oplysninger, vores retsgrundlag for behandlingen, og den periode, hvorunder dine personoplysninger opbevares</li>
        <br><li>Vores e-mails indeholder pixels, der hjælper P&G med at forstå, om du interagerer med dem, og hvilket indhold, der var interessant for dig. Få mere at vide <a href='https://privacypolicy.pg.com/da-DK/' target='_blank'><strong><u>her</u></strong></a>. Du kan til enhver tid <a href='https://preferencecenter.pg.com/da-dk/datarequests/' target='_blank'><strong><u>afmelde</u></strong></a> vores e-mails.</li>
        <br><li>Dine oplysninger behandles i et andet land end dér, hvor de blev indsamlet, herunder USA. Få mere at vide <a href='https://privacypolicy.pg.com/da-DK/' target='_blank'><strong><u>her</u></strong></a>.</li>
        <br><li>Du kan bruge dine rettigheder som registreret (du kan få indsigt i, berigtige, slette, overføre til en anden udbyder, gøre indsigelse mod eller begrænse vores behandling af de personoplysninger, som vi har om dig, eller trække dit samtykke tilbage) eller ændre dine præferencer og abonnementer ved at klikke <a href='https://preferencecenter.pg.com/da-dk/datarequests/' target='_blank'><strong><u>her</u></strong></a></li>
      </ul>
    </div>">her**</p>
    eller se vores fulde privatlivserklæring for at få flere oplysninger om behandlingen af dine oplysninger og dine <a href='https://privacypolicy.pg.com/da-DK/' target='_blank'><strong><u>privatlivsrettigheder</u></strong></a>. Du er mindst 18 år gammel og accepterer vores vilkår og <a href='https://termsandconditions.pg.com/da-dk/' target='_blank'><strong><u>betingelser</u></strong></a>.</div>`,
  'Why do we collect this data?': `<div>
                                Du registrerer dig som bruger af Braun, som <a href="https://privacypolicy.pg.com/da-DK#entities" target="_blank"><u>Procter & Gamble</u></a> er dataansvarlig for. Når du tilmelder dig, accepterer du:
                                <br>
                                <br>
                                <b>E-mail marketing tilmelding.</b> Du vil gerne modtage e-mails, tilbud og kuponer fra Braun og andre betroede <a href="https://us.pg.com/brands/" target="_blank"><u>P&G brands</u></a>.
                                <br>
                                <br>
                                <b>Dine valg.</b>Du kan benytte dine <span class="modalOpener" data-text="Som forklaret i vores politik om privatlivets fred kan du tilgå, rette, overføre til en anden udbyder eller modsige dig eller begraense vores behandling af de personlige oplysninger, vi har om dig, eller traekke dit samtykke tilbage.">databeskyttelsesrettigheder</span> eller ændre dine præferencer og abonnementer ved at klikke <a target='_blank' href='https://privacypolicy.pg.com/da-DK#preferences'><u>her</u></a>. 
                                <br>
                                <br>
                                <b>Betingelser og privatlivspolitik.</b> Du er mindst 18 år og har læst og accepterer P&G's <a target='_blank' href='https://termsandconditions.pg.com/da-dk/'><u>Vilkår og Betingelser</u></a> og <a target='_blank' href='https://privacypolicy.pg.com/da-DK'><u>Privatlivspolitikken</u></a>. For at vi bedre kan tilpasse vores kommunikation efter dine interesser, <span class="modalOpener" data-text="Vi indsamler og kan kombinere de data, som vi modtager fra dig, når du ringer til os eller besøger vores sites eller apps, når du bruger vores enheder, når du ser vores online announcer, eller som vi får fra kommercielt tilgaengelige kilder.">kombinerer vi de oplysninger</span>, du giver os ovenfor med andre oplysninger, som vi eventuelt har indsamlet om dig via forskellige kanaler og tredjeparter.
                              </div>`,

  // Guest registration page

  'Continue as a guest': 'Fortsæt som gæst',
  'and get your money back': 'og få dine penge tilbage',
  'This campaign is recommended by Braun - the online community with great product tests and offers.': 'Denne kampagne er anbefalet af Braun - online community med gode produkttests og tilbud.',

  // Forgot Password page

  'Please fill in your email address, and we will send you a reset link.': 'Indtast din e-mailadresse, så sender vi dig et link til nulstilling af din adgangskode.', // missing

  // Reset password page
  'Choose a new password': 'Vælg en ny adgangskode',
  'New password': 'Ny adgangskode',
  'Enter your new password': 'Indtast dit ny adgangskode',
  'Repeat new password': 'Bekræft kodeord',
  'Enter your new password again': 'Indtast dit ny adgangskode igen',

  // Receipt reupload page
  'Re-Upload receipt': 'Upload kvittering igen',

  // Product photo reupload page
  'Re-Upload product photo': 'Gen-upload produktfoto',

  // Iban thank you page

  'Thank you': 'Tak',
  'Thank you for resubmitting your bank details.': 'Tak, fordi du har indsendt dine bankoplysninger igen.',
  'So nothing should stand in the way of a successful transfer.': 'Så intet bør stå i vejen for en vellykket overførsel.',
  "If you have any questions related to your bank details, please contact <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.": "Hvis du har spørgsmål i forbindelse med dine bankoplysninger, bedes du kontakte <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.",
  "For other concerns, you can contact our support service at <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.": "For andre spørgsmål kan du kontakte vores supporttjeneste på <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.", // mail inconsistency

  // Expired page

  'Unfortunately the link is no longer valid!': 'Desværre er linket ikke længere gyldigt!',
  "Please participate again or contact our support team: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>": "Deltag venligst igen eller kontakt vores supportteam: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>",

  // Reclaim page
  'Ready': 'Klar',
  'Thank you for the message.': 'Tak for beskeden.',
  'We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience': 'Vi vil kontrollere refusionsbeløbet igen og derefter informere dig. Kontrollen kan tage noget tid, så vi beder om din tålmodighed',

  // Reset password success page

  'Your password has been reset and changed.': 'Din adgangskode er blevet nulstillet og ændret.',
  'You can now log in to the campaign website again. ': 'Du kan nu logge ind på kampagnens websted igen.',
  'To do this, visit the desired campaign website.': 'Det gør du ved at besøge det ønskede kampagnewebsted.',

  // Campaign is over page

  'The campaign period is over': 'Kampagneperioden er slut',
  'Thank you for your interest in the campaign, unfortunately the campaign period is over.': 'Tak for din interesse i kampagnen, men kampagneperioden er desværre slut.',

  // DOI page

  'Thank you for your registration': 'Tak for din tilmelding',
  'You have successfully registered for our newsletter.': 'Du har tilmeldt dig vores nyhedsbrev med succes.',
  'From now on you will be one of the first to find out as soon as there are free product tests, competitions and inspiring articles.': 'Fra nu af vil du være en af de første til at få besked, så snart der er gratis produkttests, konkurrencer og inspirerende artikler.',

  // Thank you page

  'Thank you for your participation': 'Tak for din deltagelse',
  'Your receipt and your data have been successfully sent to us and are now being checked.': 'Din kvittering og dine data er blevet sendt til os med succes og bliver nu kontrolleret.',
  'Reference number: ': 'Referencenummer: ',
  'As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with queries if your data was incomplete or your proof of purchase could not be recorded. This can take up to 36 hours. We ask for your patience.': 'Så snart vi har kontrolleret dine data, vil du enten modtage en e-mail med en bekræftelse eller en e-mail med forespørgsler, hvis dine data var ufuldstændige, eller hvis dit købsbevis ikke kunne registreres. Dette kan tage op til 36 timer. Vi beder om din tålmodighed.',
  'After successfully checking the proof of purchase and all other data as well as succesfully receiving the product you would like to return, we will transfer the reimbursement amount to the account you specified within 6 weeks. If anything is unclear, we will contact you again by email.': 'Når vi har kontrolleret købsbeviset og alle andre data samt modtaget det produkt, som du ønsker at returnere, overfører vi refunderingsbeløbet til den konto, du har angivet, inden for 6 uger. Hvis noget er uklart, kontakter vi dig igen via e-mail.',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation. ': 'Glem ikke, at hvis du ønsker at returnere dit produkt, skal du sende produktet tilbage til vores servicecenteradresse inden for 10 dage efter din bekræftelse af din deltagelse.',
  'Copied to clipboard': 'Kopieret til udklipsholderen',

  // SMS page

  'Please confirm your identity': 'Bekræft venligst din identitet',
  'Please enter your mobile number in the field below. We will then send you a code to confirm your participation via SMS, which you can then enter on the next page.': 'Indtast venligst dit mobilnummer i feltet nedenfor. Vi sender dig derefter en kode til bekræftelse af din deltagelse via SMS, som du kan indtaste på næste side.',
  'In order to check your identity, we need your mobile phone number. The specification of the mobile number is required to verify your participation. The phone number you provide will only be used for one-time, advertising-free verification and will be deleted by us after the campaign.': 'For at kunne kontrollere din identitet skal vi bruge dit mobiltelefonnummer. Angivelsen af mobilnummeret er nødvendig for at verificere din deltagelse. Det telefonnummer, du oplyser, vil kun blive brugt til en engangsbekræftelse uden reklame og vil blive slettet af os efter kampagnen.',
  'Phone number': 'Mobilnummer',
  'Confirmation code': 'Bekræftelseskode',
  'Enter your phone number': 'Indtast dit mobilnummer',
  'Please enter the confirmation code that we sent you via SMS into the field.': 'Indtast den bekræftelseskode, som vi har sendt dig via sms, i feltet.',
  'Change mobile number': 'Ændre mobilnummer',

  // 404 page

  'Oops!!': 'Ups!!',
  'Something went wrong!': 'Noget gik galt!',

  // Survey page
  'What were the main reasons for you to buy this product?': 'Hvad var de vigtigste grunde til, at du købte dette produkt?',
  'Please select as applicable; You have a multiple choice.': 'Vælg venligst det, der er relevant; Du har flere valgmuligheder.',
  'You have a multiple choice.': 'Du har flere valgmuligheder.',
  'Easy to use': 'Let at bruge',
  'Design': 'Design',
  'Price / good deal': 'Pris/ godt tilbud',
  'Performance': 'Ydelse',
  'Other': 'Andet',
  'Why would you like to return the product?': 'Hvorfor vil du gerne returnere produktet?',
  "It doesn't work as expected": 'Det virker ikke som forventet',
  'Wrong item size or wrong product': 'Forkert varestørrelse eller forkert produkt',
  'Changed mind after Product Purchased': 'Ændret mening efter køb af produktet',
  "Product didn't match the website or catalog Description": 'Produktet svarede ikke til beskrivelsen på hjemmesiden eller i kataloget',
  'Found better product price elsewhere': 'Fandt en bedre produktpris et andet sted',
  'Product did not meet my expectations': 'Produktet levede ikke op til mine forventninger',
  'Enter optional': 'Indtast valgfrit',

  // Return form page
  'Product name': 'Produktnavn',
  'Product EAN Number': 'Produkt EAN nummer',
  'Product Serial Number': 'Produktets serienummer',
  'Product Price for Money-back': 'Produktpris for pengene - tilbage',
  'Date of purchase': 'Købsdato',
  'Time of purchase': 'Købstidspunkt',
  'Retailer Name': 'Forhandlernavn',
  'Receipt/Invoice ID': 'Kvittering / Faktura ID',
  'Reference number of the participation': 'Referencenummer for deltagelse',
  'Enter product name': 'Indtast produktnavn',
  'Enter Product EAN number': 'Indtast produktets EAN- nummer',
  'Enter product serial number': 'Indtast produktets serienummer',
  'Enter product price for Money-back': 'Indtast produktpris for pengene tilbage',
  'Enter Date of purchase': 'Indtast købsdato',
  'Enter Time of purchase': 'Indtast tidspunkt for køb',
  'Enter retailer name': 'Indtast forhandlerens navn',
  'Enter Receipt/Invoice ID': 'Indtast kvitterings - /faktura-ID',
  'Enter Reference number of the participation': 'Indtast referencenummer for deltagelsen',

  // Legal Docs

  'website-link': 'https://se.braun.com/en',
  'home-link': '/dk',
  'terms-link': 'https://termsandconditions.pg.com/da-dk/',
  'privacy-link': 'https://privacypolicy.pg.com/da-DK',
  'preference-link': 'https://preferencecenter.pg.com/da-dk/datarequests/',
  'sitemap-link': 'https://se.braun.com/en/sitemap',
  'Return form': 'Returneringsformular',
  'Print': 'Print',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.': 'Glem ikke, at hvis du ønsker at returnere dit produkt, skal du sende produktet tilbage til vores servicecenteradresse inden for 10 dage efter din bekræftelse af din deltagelse.',
  'Denmark': 'Danmark',
  'Norway': 'Norge',
  'Finland': 'Finland',
  'Sweden': 'Sverige',

  // Validation
  'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.': 'Dette filformat understøttes ikke. Kun .jpeg-, .png-, .pdf- og .tiff-filer accepteres.',
  'There is currently a problem with our website. Please try again in a few minutes.': 'Der er i øjeblikket et problem med vores websted.Prøv venligst igen om et par minutter.',
  'The file is too big. Only files up to 16 MB are supported.': 'Filen er for stor.Der understøttes kun filer på op til 16 MB.',
  'Oops. An error has occurred. Please try again later.': 'Ups. Der er opstået en fejl. Prøv venligst igen senere.',
  validation: {
    housenumber: 'Husnummeret må kun indeholde bogstaver, mellemrum, skråstreger og bindestreger.',
    mandatory: 'Dette felt er påkrævet.',
    required: "{field} skal udfyldes",
    mobileNumber: 'Mobiltelefonnummeret skal indeholde mindst {digit} cifre.',
    alpha: '{item} * kan kun indeholde alfabetiske tegn.',
    iban: 'IBAN - Brug venligst et gyldigt IBAN-nummer fra de deltagende lande',
    bic: 'BIC skal være i det korrekte format.',
    postal: 'Postnummeret må bestå af {digit} cifre.',
    email: 'E-mail-adressen skal være i det korrekte format.',
    customRequired: {
      'Mobile number': 'Mobilnummer er et obligatorisk felt',
      code: 'Verifikationskode er et obligatorisk felt',
    },
  },
  'Please hide your personal data (name, surname, phone nr., address, etc.) on the receipt/invoice if you will have it before sending your package to Service Center.': 'Skjul venligst dine personlige data (navn, efternavn, tlf.nr., adresse osv.) på kvitteringen/fakturaen, hvis du vil have dem, inden du sender din pakke til Servicecenteret.',
  socialMedia: {
    fb: 'https://www.facebook.com/braun',
    yt: 'https://www.youtube.com/braun',
  },
}
